.code {
  background-color: #272739;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  max-width: 850px;
}

.url {
  background-color: #272739;
  color: #fff;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 14px;
  max-width: 850px;
}

* {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
}
